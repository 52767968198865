@charset "UTF-8";
/* メイン：企業インタビュー
----------------------------------------------- */
.contents_m .interview {
  margin: 40px 0 0;
  padding: 24px;
  background: linear-gradient(0deg, rgba(150, 198, 232, 0.3), rgba(150, 198, 232, 0.3)), #FFFFFF;
  border-radius: 4px;
  border: none;
}

.contents_m .interview h2 {
  position: relative;
  padding: 0;
  border: none;
  color: #3D270F;
  font-size: 2.2rem;
  text-align: center;
}

.contents_m .interview h2::after {
  content: none;
}

.contents_m .interview h2 svg {
  margin-right: 8px;
}

.contents_m .interview .body {
  margin-top: 16px;
}

.contents_m .interview .body ul li {
  overflow: initial;
  margin: 0;
}

.contents_m .interview .body ul li a {
  display: block;
  min-height: 212px;
  margin: 0;
  padding: 24px;
  background-color: #FFFFFF;
  border-radius: 6px;
  box-shadow: 0px 0px 6px rgba(35, 130, 199, 0.6);
  text-decoration: none;
}

.contents_m .interview .body ul li a:hover {
  box-shadow: 0px 0px 10px rgba(35, 130, 199, 0.6);
}

.contents_m .interview .body ul li a:hover .title,
.contents_m .interview .body ul li a:hover .text {
  text-decoration: underline;
}

.contents_m .interview .body ul li + li {
  margin-top: 8px;
}

.contents_m .interview .body ul li .img {
  float: left;
}

.contents_m .interview .body ul li .img img {
  object-fit: contain;
  width: 200px;
  border: none;
}

.contents_m .interview .body ul li .title {
  margin: 8px 0 0;
  padding: 0;
  color: #2382C7;
  font-size: 1.6rem;
  border-left: none;
}

.contents_m .interview .body ul li .cate {
  display: block;
  margin: 0;
  color: #000000;
  font-size: 1.2rem;
}

.contents_m .interview .body ul li .text {
  color: #3D270F;
}

.contents_m .interview .body ul li .text,
.contents_m .interview .body ul li .cate,
.contents_m .interview .body ul li .title {
  margin-left: 230px;
}

/* メイン：企業インタビュー(詳細)
----------------------------------------------- */
.contents_m .interview_detail .interview-headImg {
  margin-top: 24px;
  background-color: #fffdf4;
  text-align: center;
}

.contents_m .interview_detail .interview-headImg img {
  object-fit: contain;
  width: 734px;
  height: 140px;
  font-family: "object-fit: contain;";
  /*IE対策*/
}

.contents_m .interview_detail > h2 {
  border: none;
  font-size: 3.2rem;
}

.contents_m .interview_description {
  position: relative;
  width: calc(100% - 90px);
  margin: 24px 0 0 90px;
  padding: 16px 24px;
  background-color: #FFD0B9;
  border-radius: 6px;
  color: #3D270F;
  font-size: 1.8rem;
  font-weight: 700;
}

.contents_m .interview_description::before {
  content: "";
  position: absolute;
  top: 50%;
  right: calc(100% + 16px);
  width: 72.5px;
  height: 65px;
  background-image: url(../../../../common/img/top/icon-taxi.png);
  background-repeat: no-repeat;
  background-size: 72.5px 65px;
  transform: translateY(-50%);
}

.contents_m .interview_description::after {
  content: " ";
  position: absolute;
  top: 50%;
  right: 100%;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 14.5px 15px 14.5px 0;
  border-color: transparent #FFD0B9 transparent transparent;
  transform: translateY(-50%);
}

.contents_m .interview_wrap {
  margin-top: 24px;
  padding: 24px;
  background: linear-gradient(0deg, rgba(150, 198, 232, 0.3), rgba(150, 198, 232, 0.3)), #FFFFFF;
  border-radius: 4px;
}

.contents_m .interview_body {
  background: #fff;
  padding: 30px 24px 32px;
  border-radius: 4px;
}

.contents_m .interview_body h3 {
  clear: both;
  border: 0;
  margin: 0 0 8px;
  padding: 0;
  color: #2382C7;
  font-size: 1.8rem;
}

.contents_m .interview_body p {
  clear: both;
  margin: 0 0 24px;
  line-height: 1.8;
}

.contents_m .interview_body p img {
  float: left;
  margin-right: 16px;
  margin-left: 0;
}

.contents_m .interview_body p img.rightImage {
  float: right;
  margin-right: 0;
  margin-left: 16px;
}

.contents_m .interview_date {
  margin: 40px 0 0;
  font-size: 1.2rem;
  text-align: right;
}

.cuser_container > .box {
  display: none;
}

.info_table {
  margin-top: 48px;
  padding: 0;
}
